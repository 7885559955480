<template>
    <page heading="Albums">
        <template v-slot:header-action>
            <v-button :to="{ name: 'dashboard.albums.create' }">
                Create Album
            </v-button>
        </template>

        <gallery
            :items="albums"
            v-if="albums.length"
        />
    </page>
</template>

<script>
import { mapState } from 'vuex';
import store from '../../store';
import Page from '../../layouts/Page.vue';
import VButton from '../../components/Action/VButton.vue';
import Gallery from '../../components/Presentation/Gallery.vue';

export default {
    name: 'Albums',
    components: {
        Gallery,

        VButton,
        Page,
    },
    computed: {
        ...mapState('albums', ['albums']),
    },
    async beforeRouteEnter() {
        await store.dispatch('albums/fetchAlbums');
    },
};
</script>
